import React, { useState, useEffect, useContext } from "react";

//Context
import { TimeContext } from "../../config/time";

//import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

//Jquery
import apiURL from "../../config/environment";
import $ from "jquery";

export default function TrafficIncidentsTable(props) {
  const timeConfig = useContext(TimeContext).timeConfig;
  const [tableInfo, setTableInfo] = useState([]);

  const { includeAverage, tableTitleAddition } = props;
  const { filterInfo } = props;
  const { duplicate } = props;
  const { endpoint } = props;
  const { subTitle } = props;
  const { reverse } = props;
  const { filter } = props;
  const { title } = props;

  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    let isSubscribed = true;
    const token = JSON.parse(localStorage.getItem("userInfo"))?.token;

    $.ajax({
      method: "GET",
      url: apiURL + endpoint + timeConfig.value,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    }).done((res) => {
      if (isSubscribed) {
        if (filterInfo) {
          let aux = res.filter((m) => m.average === filter);
          if (reverse) aux.reverse();
          setTableInfo(aux);
        } else {
          setTableInfo(res);
        }
      }
    });

    return () => (isSubscribed = false);
  }, [timeConfig, endpoint, filterInfo, filter, reverse]);

  return (
    <React.Fragment>
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems={"flex-end"}
        sm={12}
      >
        <Grid item sm={includeAverage ? 6 : 9}>
          <Typography
            variant={duplicate ? "h6" : "h5"}
            className="condensed"
            color="primary"
            sx={{ fontWeight: 500 }}
          >
            {`${title} ${
              timeConfig.value === "cyear" ? tableTitleAddition : ""
            }`}
          </Typography>
        </Grid>
        <Grid item sm={3} container justifyContent="flex-end">
          <Typography
            variant="body2"
            color="primary"
            className="condensed"
            sx={{ textAlign: "end", fontWeight: 500, paddingRight: "1rem" }}
          >
            {subTitle}
          </Typography>
        </Grid>
        {includeAverage ? (
          <Grid item sm={3} container justifyContent="flex-end">
            <Typography variant="body2" color="primary" className="condensed">
              {"Daily Average"}
            </Typography>
          </Grid>
        ) : null}
      </Grid>

      <Grid item sm={12} sx={{ paddingTop: "2px !important" }}>
        <Divider variant="fullWidth" />
      </Grid>

      <Grid
          item
          container
          rowSpacing={2}
          sm={12}
          sx={{
            maxHeight: "171px",
            overflowY: "auto",
            paddingTop: "0 !important",
            marginTop: "1px",
              paddingRight: "1rem"
          }}
      >
      {tableInfo.map((value, key) => (
        <Grid
          key={key}
          item
          sm={12}
          container
          justifyContent={"space-between"}
          sx={{paddingTop: "14px !important"}}
        >
          <Grid item xs={includeAverage ? 6 : 9}>
            <Typography
              variant="body2"
              color="textSecondary"
              className="condensed"
            >
              {value.location}
            </Typography>
          </Grid>
          <Grid item xs={3} container justifyContent="flex-end">
            <Typography
              variant="body2"
              color="textSecondary"
              //className="condensed"
              sx={{ fontWeight: "bold" }}
            >
              {numberWithCommas(value.value)}
            </Typography>
          </Grid>
          {includeAverage ? (
            <Grid item xs={3} container justifyContent="flex-end">
              <Typography
                variant="body2"
                color="textSecondary"
                //className="condensed"
                sx={{ fontWeight: "bold" }}
              >
                {value.average}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      ))}
      </Grid>
    </React.Fragment>
  );
}
